import bike from 'assets/images/preconfiguration_img.png';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useUpdateCheckoutMetadata } from 'pages/Configurator/hooks/useUpdateCheckoutMetadata';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { Button, Icon } from 'shared/components/ui';
import { Loader } from 'shared/components/ui/Loader/Loader';
import { localizePrice } from 'shared/providers/utils';
import CheckoutStore from 'stores/Checkout.store';
import ConfiguratorStore from 'stores/Configurator.store';

import { InstalmentsModal } from '../../../../shared/components/ui/InstalmentsModal/InstalmentsModal';
import { LocalStorageKey } from '../../../../shared/helpers/localStorage';
import { gtmEvents } from '../../../../shared/types/gtmEvents';
import { SummaryDrawer } from '../SummaryDrawer/SummaryDrawer';

import styles from './MobileTabletSidebar.module.scss';
import { Section } from './Section/Section';

type SectionsType = 'colors' | 'sizes' | 'sitting position' | 'preconfiguration' | '';

type MobileSidebarProps = {};

export const MobileTabletSidebar: FC<MobileSidebarProps> = observer(() => {
    const configuratorStore = useContext(ConfiguratorStore);
    const checkoutStore = useContext(CheckoutStore);

    const [isSectionOpened, setIsSectionOpened] = useState(false);
    const [activeSection, setActiveSection] = useState<SectionsType>('');
    // const [isTablet, setIsTablet] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openSummary, setOpenSummary] = useState(false);
    const [instalmentsModal, setInstalmentsModal] = useState(false);

    const configPrice = localizePrice(configuratorStore.totalPrice / 24);

    const { t } = useTranslation();
    const navigator = useNavigate();
    const checkoutID = localStorage.getItem(LocalStorageKey.CHECKOUT_ID);

    const [updateCheckoutMetadata] = useUpdateCheckoutMetadata();

    // useEffect(() => {
    //     const userAgent = window.navigator.userAgent;
    //     if (userAgent.match(/iPhone/)) {
    //         setIsTablet(true);
    //     }
    // }, []);

    useEffect(() => {
        async function goToCheckout() {
            try {
                setLoading(true);
                configuratorStore.listenForNavigationChange = false;
                if (checkoutID) {
                    await checkoutStore.updateCheckoutLines(
                        configuratorStore.selectedParts,
                        configuratorStore.selectedColors,
                        configuratorStore.selectedBikeVariant,
                        checkoutID,
                    );
                } else {
                    await checkoutStore.createCheckout(
                        configuratorStore.selectedParts,
                        configuratorStore.selectedColors,
                        configuratorStore.selectedBikeVariant,
                    );
                }
                const id = checkoutStore.data?.id;
                if (!id) {
                    return console.error('No checkout ID.');
                }
                await checkoutStore.saveScreenshot(id);
                await configuratorStore.saveConfiguration(true);
                await updateCheckoutMetadata();
                configuratorStore.configurationExists = true;

                localStorage.setItem(LocalStorageKey.CHECKOUT_ID, id);

                window.dataLayer.push({
                    event: gtmEvents.CHECKOUT_CREATE,
                });

                await checkoutStore.sendDataToCustomerIo(gtmEvents.CHECKOUT_CREATE);
                // Replace the configurator URL with the one including SID
                // So if user uses browsers back arrow it still will load the latest configuration he created
                const SID = checkoutStore.data?.metadata.find(d => d.key === 'sid')?.value;
                navigator(`${AppRoutes.configurator}/${SID}`, { replace: true });
                // navigator(AppRoutes.checkout.replace(':id', id));
                navigator(AppRoutes.serviceUpgrade);
                // navigator(AppRoutes.checkout.replace(':id', id));
            } catch (error) {
                console.error('error', error);
            } finally {
                setLoading(false);
            }
        }

        if (checkoutStore.screenshot) {
            goToCheckout();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkoutStore.screenshot]);

    const createScreenshot = async () => {
        setLoading(true);
        checkoutStore.createScreenshot();
    };

    const openSections = (name: any) => {
        setIsSectionOpened(!isSectionOpened);
        setActiveSection(name);
    };

    const getColor = () => {
        if (configuratorStore.selectedBike?.id) {
            const variant = configuratorStore.selectedBike?.variants?.find(
                (variant: any) => variant.id === configuratorStore.selectedBikeVariant?.id,
            );
            return variant?.attributes[0]?.values[0]?.value;
        }
    };

    return (
        <div className={styles.mobile_tablet_sidebar}>
            {configuratorStore.configuratorPageLoading ? (
                <div className={styles.spinner}>
                    <Loader />
                </div>
            ) : (
                <>
                    <div className={styles.container}>
                        <div className={styles.price_container}>
                            <div className={styles.initial_container}>
                                <div
                                    className={clsx(
                                        styles.color,
                                        configuratorStore.hasSelectedColors && styles.rainbow_img,
                                    )}
                                    style={{ backgroundColor: !configuratorStore.hasSelectedColors && getColor() }}
                                    onClick={() => openSections('colors')}
                                >
                                    <div className={styles.absolute_item}>
                                        <Icon name="palette" />
                                    </div>
                                </div>

                                {configuratorStore.sizes.length > 1 && (
                                    <div className={styles.item} onClick={() => openSections('sizes')}>
                                        <h5>{configuratorStore.selectedSize.name || configuratorStore.selectedSize}</h5>
                                    </div>
                                )}

                                {configuratorStore.sittingPositions.length > 1 && (
                                    <div className={styles.item} onClick={() => openSections('sitting position')}>
                                        <h6>
                                            {t(
                                                `sittingPosition${
                                                    configuratorStore.selectedSittingPosition?.name ||
                                                    configuratorStore.selectedSittingPosition
                                                }`,
                                            )}
                                        </h6>
                                    </div>
                                )}

                                {configuratorStore.preconfigurationsList?.length > 1 && (
                                    <div className={styles.item} onClick={() => openSections('preconfiguration')}>
                                        <img src={bike} alt="thoemus bike" />
                                        <h6>{t('sidebarPreconfiguration')}</h6>
                                        <div className={styles.absolute_item}>
                                            <h6>{configuratorStore.preconfigurationsList?.length}</h6>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={styles.buttonContainer}>
                            <div className={styles.price_wrapper}>
                                <div className={styles.total_price}>
                                    {openSummary ? (
                                        <div className={styles.your_price_wrapper}>
                                            <h6>{t('priceStartingFrom')}</h6>
                                            <Icon
                                                name="question"
                                                className={styles.question_mark}
                                                onClick={() => setInstalmentsModal(true)}
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            className={clsx(styles.show_summary, openSummary && styles.hide)}
                                            onClick={() => setOpenSummary(true)}
                                        >
                                            <h6>{t('showSummary')}</h6>
                                            <Icon name="arrow_down" className={styles.icon} />
                                        </div>
                                    )}

                                    <h6>
                                        {configuratorStore.selectedBike?.configuratorPrice?.currency}{' '}
                                        {parseInt(configPrice)}
                                        <span className={styles.per_month}> / {t('month')}</span>
                                    </h6>
                                </div>
                                <Button
                                    type="primaryDark"
                                    preIcon="cart"
                                    className={styles.button}
                                    onClick={() => createScreenshot()}
                                    isLoading={loading}
                                    loaderClassName={styles.btn_loader}
                                    loadingText={t('sidebarLoadingImage')}
                                >
                                    {t('sidebarBuyButton')}
                                </Button>
                            </div>

                            {openSummary && (
                                <div className={styles.includes_tax}>
                                    <h6>{t('totalPriceIncludeTax')}</h6>
                                    <h6>
                                        {configuratorStore.selectedBike?.configuratorPrice?.currency}{' '}
                                        {localizePrice(configuratorStore.totalPrice)}
                                    </h6>
                                </div>
                            )}
                        </div>
                    </div>
                    <SummaryDrawer open={openSummary} onClose={() => setOpenSummary(false)} />
                    {instalmentsModal && (
                        <InstalmentsModal open={instalmentsModal} onClose={() => setInstalmentsModal(false)} />
                    )}
                    {activeSection === 'colors' && (
                        <Section
                            isOpen={isSectionOpened}
                            onClose={() => setIsSectionOpened(false)}
                            title={t('sidebarFrameColorTitle')}
                            setValue={async (color: any) => {
                                configuratorStore.selectedBikeVariant = {
                                    id: color.id,
                                    color: color.value,
                                    weight: color.weight,
                                    colorName: color.colorName,
                                };

                                window.dataLayer.push({
                                    event: gtmEvents.BIKE_COLOR,
                                    category: configuratorStore.bike,
                                    bikeModel: configuratorStore.selectedBike.name,
                                    color: color.colorName,
                                    hexColor: color.value,
                                });
                                await checkoutStore.sendDataToCustomerIo(gtmEvents.BIKE_COLOR, color.colorName);

                                configuratorStore.selectedColors = [];
                            }}
                            data={configuratorStore?.selectedBike?.id && configuratorStore.selectedBike?.variants}
                        />
                    )}

                    {activeSection === 'sitting position' && configuratorStore.sittingPositions.length > 1 && (
                        <Section
                            isOpen={isSectionOpened}
                            onClose={() => setIsSectionOpened(false)}
                            title={t('sittingPosition')}
                            setValue={async data => {
                                configuratorStore.selectedSittingPosition = data;

                                window.dataLayer.push({
                                    event: gtmEvents.BIKE_SITTING_POSITION,
                                    sittingPosition: data.name,
                                    category: configuratorStore.bike,
                                    bikeModel: configuratorStore.selectedBike.name,
                                });
                                await checkoutStore.sendDataToCustomerIo(gtmEvents.BIKE_SITTING_POSITION, data.name);
                            }}
                            activeValue={
                                configuratorStore.selectedSittingPosition?.name ||
                                configuratorStore.selectedSittingPosition
                            }
                            data={configuratorStore.sittingPositions}
                        />
                    )}

                    {activeSection === 'sizes' && configuratorStore.sizes.length > 1 && (
                        <Section
                            isOpen={isSectionOpened}
                            onClose={() => setIsSectionOpened(false)}
                            title={t('sidebarFrameSizeTitle')}
                            setValue={async data => {
                                configuratorStore.selectedSize = { name: data.name, id: data.id };

                                window.dataLayer.push({
                                    event: gtmEvents.BIKE_SIZE,
                                    size: data.name,
                                    category: configuratorStore.bike,
                                    bikeModel: configuratorStore.selectedBike.name,
                                });

                                await checkoutStore.sendDataToCustomerIo(gtmEvents.BIKE_SIZE, data.name);
                            }}
                            activeValue={configuratorStore.selectedSize.name ?? configuratorStore.selectedSize}
                            data={configuratorStore.sizes}
                        />
                    )}

                    {activeSection === 'preconfiguration' && (
                        <Section
                            isOpen={isSectionOpened}
                            onClose={() => setIsSectionOpened(false)}
                            title={t('sidebarPreconfigurationsTitle')}
                            setValue={async data => {
                                configuratorStore.setPreconfigurationId(data.id);

                                window.dataLayer.push({
                                    event: gtmEvents.BIKE_PRECONFIGURATION,
                                    preconfiguration: data.title,
                                });

                                await checkoutStore.sendDataToCustomerIo(gtmEvents.BIKE_PRECONFIGURATION, data.title);
                            }}
                            activeValue={configuratorStore.preconfigurationId}
                            data={configuratorStore.preconfigurationsList}
                        />
                    )}
                </>
            )}
        </div>
    );
});
