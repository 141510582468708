import fallbackImage from 'assets/images/mock-bike.png';
import { observer } from 'mobx-react-lite';
import React, { FC, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Icon, Modal } from 'shared/components/ui';

import ConfiguratorStore from '../../../../stores/Configurator.store';

import styles from './PreconfigurationModal.module.scss';

type PreconfigurationModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const PreconfigurationModal: FC<PreconfigurationModalProps> = observer(({ isOpen, onClose }) => {
    const store = useContext(ConfiguratorStore);
    const { t } = useTranslation();

    const prevPreconfigurationImage = store.preconfigurationsList?.find(
        (conf: any) => conf.id === store.preconfigurationChanged?.prevId,
    )?.cover;

    const currentPreconfigurationImage = store.preconfigurationsList?.find(
        (conf: any) => conf.id === store.preconfigurationChanged?.id,
    )?.cover;

    return (
        <Modal isOpen={isOpen} onClose={onClose} maxWidth={444} className={styles.preconfiguration_modal}>
            <div className={styles.text}>
                <h3 className={styles.title}>{t('preconfigurationSelect')}</h3>
                <h6 className={styles.subtitle}>
                    <Trans
                        i18nKey="preconfigurationWasChanged"
                        components={{ strong: <strong /> }}
                        values={{ title: store.preconfigurationChanged?.title }}
                    />
                </h6>
                <div className={styles.images}>
                    <img
                        src={`${
                            prevPreconfigurationImage || fallbackImage
                        }?w=105&auto=compress&trim=color&trim-tol=50&bri=7`}
                        alt=""
                    />
                    <Icon name="arrow_alt" />
                    <img
                        src={`${
                            currentPreconfigurationImage || fallbackImage
                        }?w=105&auto=compress&trim=color&trim-tol=50&bri=7`}
                        alt=""
                    />
                </div>
                <div className={styles.buttons}>
                    <Button
                        type="ghost"
                        onClick={() => {
                            store.setPreconfigurationId(store.preconfigurationChanged?.id, true);
                            store.preconfigurationChanged = '';
                            onClose();
                        }}
                    >
                        {t('preconfigurationSelectYes')}
                    </Button>
                    <h6
                        className={styles.exit_button}
                        onClick={() => {
                            // store.setPreconfigurationId(store.preconfigurationChanged?.id, false);
                            store.preconfigurationId = store.preconfigurationChanged?.prevId;
                            store.preconfigurationChanged = '';
                            onClose();
                        }}
                    >
                        {t('preconfigurationSelectNo')}
                    </h6>
                </div>
            </div>
        </Modal>
    );
});
