import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Input, Modal } from 'shared/components/ui';
import { LocalStorageKey } from 'shared/helpers/localStorage';
import { EMAIL_REGEX } from 'shared/helpers/validators';
import { gtmEvents } from 'shared/types/gtmEvents';
import CheckoutStore from 'stores/Checkout.store';

import styles from './EmailPopup.module.scss';

type EmailPopupProps = {
    isOpen: boolean;
    onClose: () => void;
};

interface EmailFormType {
    name: string;
    email: string;
    message: string;
}

export const EmailPopup: React.FC<EmailPopupProps> = ({ isOpen, onClose }) => {
    // const navigate = useNavigate();
    const store = useContext(CheckoutStore);
    const { t } = useTranslation();
    // const [isMarketingAccepted, setIsMarketingAccepted] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<EmailFormType>();

    const onSubmit = async (data: EmailFormType) => {
        if (data.email) {
            localStorage.setItem(LocalStorageKey.EMAIL, data.email);
        }

        window.dataLayer.push({
            event: gtmEvents.BIKE_MODEL_EMAIL_POPUP,
            email: data.email,
        });
        await store.sendDataToCustomerIo(gtmEvents.BIKE_MODEL_EMAIL_POPUP);

        onClose();
        // navigate(`${AppRoutes.configurator}?bike=${store.selectedBike?.id}`);
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} maxWidth={444} className={styles.email_modal}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h3 className={styles.title}>{t('emailModalTitle')}</h3>
                <h6 className={styles.subtitle}>
                    <Trans i18nKey="emailModalSubtitle" components={{ strong: <strong /> }} />
                </h6>
                <h5 className={styles.email_label}>{t('yourEmail')}</h5>
                <Input
                    placeholder="alex.wonderlist@example.com"
                    {...register('email', {
                        required: false,
                        pattern: {
                            value: EMAIL_REGEX,
                            message: t('emailIncorrect'),
                        },
                    })}
                    error={errors.email?.message}
                />
                {/*<div className={styles.checkbox_container}>*/}
                {/*    <Checkbox*/}
                {/*        onChange={() => setIsMarketingAccepted(!isMarketingAccepted)}*/}
                {/*        checked={isMarketingAccepted}*/}
                {/*    />*/}
                {/*    <span className={styles.checkbox_text}>{t('emailAcceptMarketing')}</span>*/}
                {/*</div>*/}
                <Button type="primary" htmlType="submit" className={styles.button}>
                    {t('next')}
                </Button>
            </form>
        </Modal>
    );
};
